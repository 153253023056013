// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-templates-event-js": () => import("./../../../src/components/templates/event.js" /* webpackChunkName: "component---src-components-templates-event-js" */),
  "component---src-components-templates-location-js": () => import("./../../../src/components/templates/location.js" /* webpackChunkName: "component---src-components-templates-location-js" */),
  "component---src-components-templates-tutors-js": () => import("./../../../src/components/templates/tutors.js" /* webpackChunkName: "component---src-components-templates-tutors-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-atelier-js": () => import("./../../../src/pages/atelier.js" /* webpackChunkName: "component---src-pages-atelier-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jobs-js": () => import("./../../../src/pages/jobs.js" /* webpackChunkName: "component---src-pages-jobs-js" */),
  "component---src-pages-online-events-js": () => import("./../../../src/pages/online-events.js" /* webpackChunkName: "component---src-pages-online-events-js" */),
  "component---src-pages-ordercomplete-js": () => import("./../../../src/pages/ordercomplete.js" /* webpackChunkName: "component---src-pages-ordercomplete-js" */),
  "component---src-pages-orderfailed-js": () => import("./../../../src/pages/orderfailed.js" /* webpackChunkName: "component---src-pages-orderfailed-js" */),
  "component---src-pages-policies-js": () => import("./../../../src/pages/policies.js" /* webpackChunkName: "component---src-pages-policies-js" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-pages-timetable-js": () => import("./../../../src/pages/timetable.js" /* webpackChunkName: "component---src-pages-timetable-js" */)
}

